import React from 'react';
import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';
import {Container} from '@mui/material';
import Topbar from '~/components/Layout/Topbar';
import Sidebar from '~/components/Layout/Sidebar';

const Layout: React.FC<{}> = () => {
	return (
		<Styles>
			<Topbar />
			<Sidebar />
			<Container className="main">
				<Outlet />
			</Container>
		</Styles>
	);
};

const Styles = styled.div`
	.main {
		box-sizing: border-box;
		width: calc(100% - 82px);
		margin: ${({ theme }) => theme.spacing(18, 'auto', 'auto', '82px')};
		padding: ${({ theme }) => theme.spacing(0, 5, 5, 5)};
		overflow-x: hidden;
		max-width: 100%;
	}
`;

export default Layout;

import styled from '@emotion/styled';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, Tooltip } from '@mui/material';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDarkMode } from '~/utils/contexts';
import { useAuth } from '~/hooks/auth';
import { useEffect } from 'react';
import { bakeLocalStorage, readLocalStorage } from '~/utils/storage';

interface LayoutRoute {
	text: string;
	icon: IconName;
	link: string;
	show?: boolean;
	justifyContent?: string;
}

const Sidebar: React.FC = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const location = useLocation();

	const { user } = useAuth();
	const isAdmin = user?.permissions?.admin;

	const routes: LayoutRoute[] = [
		{
			text: 'Home',
			icon: 'house',
			link: '/',
		},
		{
			text: 'Vans',
			icon: 'van-shuttle',
			link: '/vans',
			show: !isAdmin,
		},
		{
			text: 'Customers',
			icon: 'helmet-safety',
			link: '/customers',
		},
		{
			text: 'Documents',
			icon: 'paperclip',
			link: '/documents',
		},
		{
			show: !isAdmin,
			text: 'Employees',
			icon: 'users',
			link: '/employees',
		},
		{
			show: !isAdmin,
			text: 'Tags',
			icon: 'tag',
			link: '/tags',
			justifyContent: 'center',
		},
	];
	const isActiveRoute = (index: number) =>
		routes[index].link === `/${location.pathname.split('/')[1]}`;

	const { darkMode, onToggle } = useDarkMode();

	useEffect(() => {
		if (readLocalStorage('darkmode')) {
			void onToggle();
		}
	}, []);

	const bakeLocal = () => {
		if (darkMode) {
			bakeLocalStorage('darkmode', false);
		} else {
			bakeLocalStorage('darkmode', true);
		}
	};
	return (
		<Styles>
			<Drawer variant="permanent" anchor="left" className="sidebar-navigation">
				<List disablePadding className="icon-list-margins">
					{routes.map(
						(route, index) =>
							!route.show && (
								<Tooltip title={route.text} placement="right" key={index}>
									<ListItem key={index} disablePadding>
										<ListItemButton
											sx={{
												justifyContent: route?.justifyContent
													? route?.justifyContent
													: undefined,
											}}
											onClick={() => navigate(route.link)}
										>
											<ListItemIcon
												className={`list-item-icon ${
													isActiveRoute(index) ? 'active' : 'inactive'
												}`}
											>
												<FontAwesomeIcon
													size={'sm'}
													icon={['fas', route.icon]}
													color={theme.palette.primary.main}
												/>
											</ListItemIcon>
										</ListItemButton>
									</ListItem>
								</Tooltip>
							)
					)}
				</List>
				<List disablePadding className="icon-list-darkMode">
					<Tooltip title={`${darkMode ? 'Light' : 'Dark'} mode`} placement="right">
						<ListItem disablePadding>
							<ListItemButton
								onClick={() => {
									onToggle();
									bakeLocal();
								}}
								className={'dark-toggle'}
							>
								<ListItemIcon className="list-item-icon inactive">
									<FontAwesomeIcon
										size={'sm'}
										icon={['fas', `${darkMode ? 'sun' : 'moon'}`]}
									/>
								</ListItemIcon>
							</ListItemButton>
						</ListItem>
					</Tooltip>
				</List>
			</Drawer>
		</Styles>
	);
};

const Styles = styled.div`
	.sidebar-navigation {
		z-index: 50 !important;
	}

	.icon-list-margins {
		margin-top: 120px;
	}

	.list-item-icon {
		min-width: 0;
		justify-content: center;
		padding: ${({ theme }) => theme.spacing(2)};
		border-radius: 8px;

		svg {
			color: ${({ theme }) => theme.palette.mode === 'dark' && theme.palette.action.active};
		}

		&.active {
			background-color: ${({ theme }) => theme.palette.action.selected};
		}
	}

	.icon-list-darkMode {
		margin-top: auto;
	}
`;
export default Sidebar;

import { Theme } from '@mui/material';
import merge from 'deepmerge';
import TextField from './TextField';
import Button from './Button';
// import Link from './Link';
import IconButton from '~/theme/components/IconButton';
import Card from '~/theme/components/Card';
// import Container from '~/theme/components/Container';
// import Chip from '~/theme/components/Chip';

const components = (theme: Theme) =>
	merge.all([
		// TextField(theme),
		Button(theme),
		// Link(theme),
		// IconButton(theme),
		// Card(theme),
		// Container(theme),
		// Chip(theme),
	]);
export default components;

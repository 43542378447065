import React, { useEffect, useState } from 'react';
import { Button, Card, Stack, Typography } from '@mui/material';
import { FallbackRender } from '@sentry/react';
import styled from '@emotion/styled';
import { seoTitle } from '~/utils/common';
import { Helmet } from 'react-helmet';

export const ErrorBoundary: FallbackRender = ({ error, resetError, notFound }) => {
	console.log(error, 'error');
	const status = (error as any)?.response?.status;
	const data = (error as any)?.response?.data;
	const errorName = (error as any)?.response?.data?.errorName || (error as any)?.name;
	const [errorMessage, setErrorMessage] = useState<{
		title: string;
		description: string;
		buttonText: string;
		reload: boolean;
	}>({
		title: 'Sorry, an error occurred',
		description: 'This has been reported to our team, please try again later.',
		buttonText: 'Try again',
		reload: true,
	});

	useEffect(() => {
		if (notFound || status === 404) {
			setErrorMessage({
				title: 'Page not found',
				description:
					"The page you're trying to load was not found. Please contact an admin if you think this page should exist.",
				buttonText: 'Return home',
				reload: false,
			});
		}
	}, []);

	return (
		<Styles>
			<Helmet>
				<title>{seoTitle('Error')}</title>
			</Helmet>
			<Stack
				justifyContent="center"
				alignItems="center"
				textAlign="center"
				spacing={2}
				className="error"
			>
				<Card sx={{ maxWidth: '500px', p: '16px' }} className="error-card">
					<img width={300} alt="logo" src={`/assets/att-management-logo.svg`} />
					<Typography component="h1" className="error-card-title" variant="h2">
						{errorMessage.title}
					</Typography>
					<Typography sx={{ p: '16px' }} className="error-card-description">
						{errorMessage.description}
					</Typography>
					<Button
						variant="contained"
						className="error-card-button"
						onClick={() => {
							errorMessage.reload ? resetError : (window.location.href = '/');
						}}
					>
						{errorMessage.buttonText}
					</Button>
				</Card>
			</Stack>
		</Styles>
	);
};

const Styles = styled.div`
	.error {
		background-color: #f0f2f7;
		width: 100vw;
		height: 100vh;
		font-family: Montserrat, Open sans, Helvetica, Arial, sans-serif;

		&-card {
			box-shadow: 0px 2px 1px -1px rgba(145, 158, 171, 0.2),
				0px 1px 1px 0px rgba(145, 158, 171, 0.14), 0px 1px 3px 0px rgba(145, 158, 171, 0.14);

			&-title {
				font-weight: bold;
				font-family: Montserrat, Open sans, Helvetica, Arial, sans-serif;
				font-size: 1.375rem;
			}

			&-description {
				font-weight: 300;
			}

			&-button {
				background-color: #3a4d91;
				text-transform: inherit;
			}
		}
	}
`;

export default ErrorBoundary;

import styled from '@emotion/styled';
import { passeReportJSONReadOnly, seoTitle } from '~/utils/common';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Breadcrumbs,
	Grid,
	Link,
	Paper,
	Stack,
	Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import api, { useGet } from '~/utils/api';
import { IRequestVanReportChecklist } from '~/pages/Vans/VanChecklist';
import Loading from '~/components/Loading';
import { Masonry } from '@mui/lab';
import VanCard from '~/components/Vans/VanCard';
import { IRequestVan } from '~/models/Van';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';

const parseName = (name: string) => {
	return name.replace(/[/-]/g, ' ');
};

interface ISelectItem {
	name: string | null;
	enabled: boolean;
	id: string | null;
	note: string | null;
}

const ChecklistReadOnly = () => {
	const vanReportId = location.pathname.split('/')[4];
	const vanId = location.pathname.split('/')[2];
	const { data: van, isLoading } = useGet<IRequestVan>(api.van.van + vanId);
	const { data: vanReport, isLoading: vanLoading } = useGet<IRequestVanReportChecklist>(
		api.vanReport.vanReport(vanReportId),
		{
			enabled: !!vanReportId,
		}
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	if (!vanReport?.checklist) return <Loading />;

	const initialValues = passeReportJSONReadOnly(vanReport?.checklist);
	const date = new Date(`${vanReport?.year}/${vanReport?.month}/01`);

	return (
		<Styles>
			<Helmet>
				<title>
					{seoTitle(`Van | ${van?.registration} | Checklist | ${vanReport?.month}`)}
				</title>
			</Helmet>
			<Breadcrumbs aria-label="breadcrumb">
				<Link underline="hover" color="inherit" href="/vans">
					Vans
				</Link>
				<Link underline="hover" color="inherit" href={`/vans/${van?._id}`}>
					{van?.make} {van?.model}
				</Link>
				<Link underline="hover" color="inherit" href={`/vans/${van?._id}#checklists`}>
					Checklists
				</Link>
				<Typography color="text.primary">{format(date, 'MMMM')} report</Typography>
			</Breadcrumbs>
			<Typography variant="h1" component="h1" className="read-only-title" sx={{ my: 3 }}>
				Van Report for {format(date, 'MMMM yyyy')}
			</Typography>
			{!!van && <VanCard van={van} />}
			<Paper className="checklist">
				<Stack sx={{ margin: '0 auto', textAlign: 'center', pb: 3 }}>
					<Typography component={'p'} variant={'h2'} className="">
						Is your van fully stocked?
					</Typography>{' '}
					<Typography component={'p'} variant={'h3'} fontWeight={400}>
						Do you have everything to complete your job?
					</Typography>
				</Stack>
				<Masonry columns={{ xs: 1, sm: 1, md: 3 }} spacing={2}>
					{initialValues.map((item, idx) => (
						<Paper square variant="outlined" sx={{ p: 3 }}>
							<Typography component={'p'} variant={'h3'} className="capitalise">
								{parseName(item.name)}
							</Typography>
							<Box>
								{item.items.map((selectItem: ISelectItem, index) => {
									return (
										<Accordion
											key={index}
											expanded={!selectItem.enabled}
											className={`accordion ${
												!selectItem.enabled ? 'active' : 'inactive'
											}`}
										>
											<AccordionSummary className="accordion-summary">
												<Typography
													variant="body1"
													className="accordion-summary-text"
												>
													{selectItem.name}
												</Typography>

												<div>
													{selectItem.enabled ? (
														<Box
															className={
																'accordion-box accordion-box-green'
															}
														>
															<CheckIcon />
														</Box>
													) : (
														<Box
															className={
																'accordion-box accordion-box-red'
															}
														>
															<CloseIcon />
														</Box>
													)}
												</div>
											</AccordionSummary>
											{!selectItem.enabled &&
												selectItem.note &&
												selectItem.note.length > 0 && (
													<AccordionDetails className="accordion-details">
														{!selectItem.enabled && (
															<Typography>
																{selectItem.note ?? ''}
															</Typography>
														)}
													</AccordionDetails>
												)}
										</Accordion>
									);
								})}
							</Box>
						</Paper>
					))}
				</Masonry>
			</Paper>
		</Styles>
	);
};

const Styles = styled.div`
	.accordion-box {
		min-width: 36px;
		min-height: 36px;
		width: 100%;
		height: 100%;
		border: 1px solid;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;

		&-green {
			border-color: green;
		}

		&-red {
			border-color: red;
		}
	}

	.checklist {
		// margin-top: ${({ theme }) => theme.spacing(6)};
		padding: ${({ theme }) => theme.spacing(6, 6, 6, 6)};
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		margin: ${({ theme }) => theme.spacing(6)} 0;
	}

	.capitalise {
		text-transform: capitalize;
	}

	.accordion {
		transition: 0.3s ease;
		box-shadow: none;

		&.active {
			background-color: ${({ theme }) => theme.palette.primary.lighter};
		}

		&-summary {
			flex-direction: row-reverse;
			gap: ${({ theme }) => theme.spacing(2)};

			.MuiAccordionSummary-content {
				align-items: center;
			}

			&-text {
				margin-right: auto;
			}
		}

		&-details {
			&-input {
				margin-top: 0 !important;

				input {
					padding-left: 0.5rem !important;
				}
			}
		}
	}
`;
export default ChecklistReadOnly;

import styled from '@emotion/styled';
import {
	AppBar,
	TextField,
	Stack,
	Avatar,
	Typography,
	IconButton,
	Tooltip,
	createTheme,
} from '@mui/material';
import Sidebar from '~/components/Layout/Sidebar';
import * as React from 'react';
import { useAuth } from '~/hooks/auth';
import { getAvatar } from '~/utils/common';
import LogoutIcon from '@mui/icons-material/Logout';
import { ThemeProvider } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

const Topbar: React.FC = () => {
	const { user, logout } = useAuth();
	const navigate = useNavigate();

	return (
		<Styles>
			<AppBar className="topbar" position="fixed">
				<ThemeProvider
					theme={createTheme({
						palette: {
							mode: 'dark',
						},
					})}
				>
					<img
						className="topbar__image"
						src="/assets/att-management-logo.svg"
						alt="logo"
					/>

					<Stack
						className="topbar__profile"
						direction="row"
						alignItems="center"
						spacing={2}
					>
						<Stack direction="row" onClick={() => navigate('account')}>
							<Avatar
								onClick={() => navigate('account')}
								className="topbar__profile__avatar"
								src={getAvatar(user?._id!)}
							></Avatar>
							<Stack className="topbar__profile__typo">
								<Typography variant={'body1'}>
									{user?.firstName} {user?.lastName}
								</Typography>
								<Typography variant={'caption'}>
									{user?.permissions?.admin ? 'Admin' : 'Tyre Technician'}
								</Typography>
							</Stack>
						</Stack>
						<Tooltip title="Logout" className="topbar__profile__logout">
							<IconButton onClick={() => logout()}>
								<LogoutIcon />
							</IconButton>
						</Tooltip>
					</Stack>
				</ThemeProvider>
			</AppBar>
			<Sidebar />
		</Styles>
	);
};

const Styles = styled.div`
	.topbar {
		max-height: 100px;
		height: 100%;
		position: fixed;
		z-index: 1201;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: ${({ theme }) => theme.spacing(0.5, 3)};

		&__search {
			width: 100%;
			margin-top: 0 !important;
			margin-left: auto;
			max-width: 520px;

			&__icon {
				margin-right: 10px;
			}
		}

		&__image {
			max-width: max-content;
			height: 70px;
			width: auto;
			margin-right: auto;
		}

		&__profile {
			padding: ${({ theme }) => theme.spacing(0, 4)};
			margin-left: ${({ theme }) => theme.spacing(4)};
			border-left: 2px solid ${({ theme }) => theme.palette.divider};

			&:hover {
				cursor: pointer;
			}

			&__avatar {
				height: 44px;
				width: 44px;
				margin-right: 1rem;
			}
		}
	}
`;
export default Topbar;

import React, { Suspense, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CircularProgress, CssBaseline, ThemeProvider, useTheme } from '@mui/material';
import SnackbarProvider, { useSnackbar } from '~/components/Snackbar';
import { Workbox } from 'workbox-window';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundary as _ErrorBoundary } from '~/components/ErrorBoundary';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Global } from '@emotion/react';
import Theme from '~/components/Theme';
import { rootRoutes } from '~/routes';
import { useRoutes } from '~/utils/contexts';

import {
	faBan,
	faCloudCheck,
	faEngine,
	faEngineWarning,
	faThumbsUp,
	faEye as fasEye,
	faArrowLeft as fasArrowLeft,
	faCloudUpload as fasCloudUpload,
	faHouse,
	faHelmetSafety,
	faVanShuttle,
	faUser,
	faLock,
	faSearch,
	faListDots,
	faEllipsisV,
	faTriangle,
	faCircle,
	faCircleQuarters,
	faWrench,
	faCircleExclamationCheck,
	faExclamation,
	faEngineExclamation,
	faTriangleExclamation,
	faChevronRight,
	faChevronLeft,
	faEnvelope,
	faKey,
	faFaceSmileBeam,
	faIdCard,
	faIdBadge,
	faIdCardClip,
	faChevronsRight,
	faPlus,
	faPenFancy,
	faClose,
	faPaperPlane,
	faPenAlt,
	faPenNib,
	faChevronDown,
	faUsers,
	faTag,
	faPaperclip,
	faFile,
	faXmark,
	faMoon,
	faSun,
	faCircleExclamation,
	faBrakeWarning,
	faPencil,
	faBadgeCheck,
	faTrash,
	faTrashRestore,
} from '@fortawesome/pro-solid-svg-icons';
import {
	faPieChart as fadPieChart,
	faMountainSun as fadMountainSun,
	faServer as fadServer,
	faTablet as fadTablet,
	faSignsPost as fadSignsPost,
	faQrcode as fadQrcode,
	faImages as fadImages,
	faBarsStaggered as fadBarsStaggered,
} from '@fortawesome/pro-duotone-svg-icons';
import globalStyles from './globalStyles';
import RoutesProvider from '~/components/Routes';
import AuthProvider from '~/utils/auth';

library.add(
	faFile,
	faTrashRestore,
	faPencil,
	fasEye,
	faTrash,
	fasArrowLeft,
	fadPieChart,
	fadMountainSun,
	fadServer,
	fadTablet,
	fadSignsPost,
	fadQrcode,
	fadImages,
	fasCloudUpload,
	fadBarsStaggered,
	faHouse,
	faHelmetSafety,
	faVanShuttle,
	faUser,
	faLock,
	faSearch,
	faEllipsisV,
	faTriangle,
	faCircle,
	faCircleQuarters,
	faCircleExclamationCheck,
	faExclamation,
	faWrench,
	faTriangleExclamation,
	faBadgeCheck,
	faChevronRight,
	faChevronLeft,
	faEnvelope,
	faKey,
	faFaceSmileBeam,
	faIdCardClip,
	faChevronsRight,
	faPlus,
	faPenNib,
	faPaperPlane,
	faChevronDown,
	faUsers,
	faWrench,
	faBan,
	faCloudCheck,
	faTriangleExclamation,
	faCircle,
	faEngine,
	faEngineWarning,
	faEngineExclamation,
	faThumbsUp,
	faCircle,
	faExclamation,
	faClose,
	faHouse,
	faPaperclip,
	faXmark,
	faSun,
	faTag,
	faMoon,
	faCircleExclamation,
	faBrakeWarning,
	faBadgeCheck
);
import { LicenseInfo } from '@mui/x-license-pro';
import Loading from '~/components/Loading';

LicenseInfo.setLicenseKey(
	'081afd2dfa89876297c4c2c6d31c176fTz02NjYyNCxFPTE3MTU4ODc1Mjc5OTcsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			useErrorBoundary: (error: any) =>
				!(error?.response?.status >= 400 && error?.response?.status < 500),
			refetchOnWindowFocus: false,
			networkMode: process.env.NODE_ENV === 'development' ? 'always' : undefined,
		},
		mutations: {
			// Offline support
			networkMode: process.env.NODE_ENV === 'development' ? 'always' : undefined,
		},
	},
});

const App = () => {
	const [initialising, setInitialising] = useState(true);
	const theme = useTheme();
	const { router, routerRoutes } = useRoutes();

	// TODO: Use real precache from RDM in the future if it's an experience

	return (
		<Suspense fallback={<Loading />}>
			<Global styles={globalStyles(theme)} />
			{router}
		</Suspense>
	);
};

const Root = () => {
	return (
		<ErrorBoundary
			FallbackComponent={_ErrorBoundary}
			onReset={() => {
				// reset the state of your app so the error doesn't happen again
			}}
		>
			<BrowserRouter>
				<Theme>
					<QueryClientProvider client={queryClient}>
						<SnackbarProvider>
							<RoutesProvider routes={rootRoutes}>
								<AuthProvider>
									<CssBaseline />
									<App />
								</AuthProvider>
							</RoutesProvider>
						</SnackbarProvider>
					</QueryClientProvider>
				</Theme>
			</BrowserRouter>
		</ErrorBoundary>
	);
};

// @ts-ignore
createRoot(document.getElementById('root')).render(<Root />);

if ('serviceWorker' in navigator && process.env.NODE_ENV !== 'development') {
	const wb = new Workbox('/sw.js');

	wb.addEventListener('waiting', (event) => {
		console.log('WAITING');

		wb.addEventListener('controlling', () => {
			// At this point, reloading will ensure that the current
			// tab is loaded under the control of the new service worker.
			// Depending on your web app, you may want to auto-save or
			// persist transient state before triggering the reload.
			window.location.reload();
		});

		wb.messageSkipWaiting();

		window.addEventListener('beforeunload', async () => {
			await wb.messageSkipWaiting();
		});
	});

	wb.register();
}

import React, { lazy } from 'react';
import type { PreRoute } from '~/models/route';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '~/components/Layout';
import ErrorBoundary from '~/components/ErrorBoundary';
import Checklist from '~/components/Employees/Checklist';
import ChecklistReadOnly from '~/components/Vans/ChecklistReadOnly';

const Tags = lazy(() => import('../pages/tags'));
const Customer = lazy(() => import('../pages/Customers/Customer'));
const Customers = lazy(() => import('../pages/Customers/Customers'));
const CustomerReport = lazy(() => import('../pages/Customers/CustomerReport'));
const VanChecklist = lazy(() => import('../pages/Vans/VanChecklist'));
const DocumentsPage = lazy(() => import('../pages/DocumentsPage'));
const Account = lazy(() => import('../pages/Account'));
const AdminEmployee = lazy(() => import('../components/AdminEmployee'));
const Vans = lazy(() => import('../components/Vans'));
const Employees = lazy(() => import('../components/Employees'));
const Van = lazy(() => import('../pages/Vans/Van'));
const Reference = lazy(() => import('../pages/reference'));
const Home = lazy(() => import('../pages/index'));
const Offline = lazy(() => import('../pages/offline'));
const Private = lazy(() => import('../components/Private'));
const Login = lazy(() => import('../pages/login'));
const Register = lazy(() => import('../pages/register'));
const Logout = lazy(() => import('../pages/logout'));
const ChangeEmail = lazy(() => import('../pages/change_email'));
const VerifyEmail = lazy(() => import('../pages/verify_email'));
const ForgotPassword = lazy(() => import('../pages/forgot_password'));
const ConfirmPassword = lazy(() => import('../pages/confirm_password'));
const Concierge = lazy(() => import('../pages/concierge'));
const MagicLink = lazy(() => import('../pages/magic_link'));

const routes: PreRoute[] = [
	{
		path: '/reference',
		element: <Reference />,
	},
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: '/logout',
		element: <Logout />,
	},
	{
		path: '/register',
		element: <Register />,
	},
	{
		path: '/change-email',
		element: <ChangeEmail />,
	},
	{
		path: '/verify-email',
		element: <VerifyEmail />,
	},
	{
		path: '/forgot-password',
		element: <ForgotPassword />,
	},
	{
		path: '/concierge',
		element: <Concierge />,
	},
	{
		path: '/forgot-password-confirm',
		element: <ConfirmPassword />,
	},
	{
		path: '/magic-link',
		element: <MagicLink />,
	},
	{
		path: '/',
		element: <Private />,
		children: [
			{
				path: '/',
				element: <Layout />,
				children: [
					{
						index: true,
						element: <Home />,
					},
					{
						path: '/offline',
						element: <Offline />,
					},
					{
						path: '/account',
						element: <Account />,
					},
					{
						path: '/vans',
						children: [
							{
								index: true,
								element: <Vans />,
							},
							{
								path: '/vans/:id',
								element: <Van />,
							},
							{
								path: '/vans/:id/checklist/',
								element: <VanChecklist />,
							},
							{
								path: '/vans/:id/checklist/:checklistId',
								element: <ChecklistReadOnly />,
							},
						],
					},

					{
						path: '/employees',
						children: [
							{
								index: true,
								element: <Employees />,
							},
							{
								path: '/employees/:id',
								element: <AdminEmployee />,
							},
						],
					},
					{
						path: '/tags',
						children: [
							{
								index: true,
								element: <Tags />,
							},
						],
					},
					{
						path: '/development_and_testing',
						children: [
							{
								index: true,
								element: <Checklist />,
							},
						],
					},
					{
						path: '/customers',
						children: [
							{
								index: true,
								element: <Customers />,
							},
							{
								path: '/customers/:id',
								element: <Customer />,
							},
							{
								path: '/customers/:id/reports',
								element: <CustomerReport />,
							},
						],
					},
					{
						path: '/documents',
						element: <DocumentsPage />,
					},
				],
			},
			{
				path: '/customers',
				children: [
					{
						index: true,
						element: <Customers />,
					},
					{
						path: '/customers/:id',
						element: <Customer />,
					},
					{
						path: '/customers/:id/reports',
						element: <CustomerReport />,
					},
				],
			},
			{
				path: '/documents',
				element: <DocumentsPage />,
			},
			{
				path: '/*',
				element: <ErrorBoundary notFound={true} />,
			},
		],
	},
];

export const navigation = [
	{
		name: 'Home',
		path: '/',
		icon: <FontAwesomeIcon icon={['fad', 'pie-chart']} />,
	},
];

export default routes;
